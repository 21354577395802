<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app dark :color="$const.color.primary">
        <v-list dense>
            <template v-for="(item, i) in items">
                <v-divider v-if="item.border_top" :key="`divider${i}`" />
                <item :item="item" :key="i" />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>

import Item from './Item.vue'

export default {
    name: 'Sidebar',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { Item },
    data() {
        return {
            loading: {
                initial: false
            },
            items: [
                { icon: 'mdi-book-edit-outline', text: 'Задания', id: 'tasks', href: '/task', children: [] },
                { icon: 'mdi-file-tree', text: 'Работы', href: '/job' },
                { icon: 'mdi-account-arrow-left-outline', text: 'Пользователи', href: '/user' },
                { icon: 'mdi-notebook-outline', text: 'Темы', href: '/theme' },
                { icon: 'mdi-folder-information-outline', text: 'Инструкции к работе', href: '/hint' },
                { icon: 'mdi-book-information-variant', text: 'Критерии оценивания', href: '/scoring-criteria' },
                { icon: 'mdi-keyboard-settings-outline', text: 'Листы вирт. клавиатуры', href: '/keyboard-list' },
                { icon: 'mdi-information-slab-box-outline', text: 'Инструкции интерактивов', href: '/interactives-instruction' },
                { icon: 'mdi-notebook-outline', text: 'Поурочное планирование', href: '/lesson-plan' },
                // { icon: 'mdi-package-variant-closed', text: 'Наборы контента', href: '/content-kit' },
                { icon: 'mdi-image-multiple-outline', text: 'Медиа менеджер', href: '/media-manager' },
                { icon: 'mdi-cog-outline', text: 'Прочее', children: [

                    ...(
                        // TODO: нужно переработать эту схему защиты роутов на классическую систему ролей
                        this.$store.getters['user/isSuperAdmin']
                        ? [
                            { icon: 'mdi-swap-horizontal', text: 'Смена вариантов', href: '/settings/swap-options' }
                        ]
                        : []
                    ),
                    { icon: 'mdi-circle-small', text: 'Выгрузка оценок', href: '/settings/scores-report' },
                    { icon: 'mdi-circle-small', text: 'Анализ присутствия тем', href: '/settings/task-to-theme-report' },
                    { icon: 'mdi-circle-small', text: 'Другие отчёты', href: '/settings/reports' },
                ] },
                { icon: 'mdi-logout-variant', text: 'Выйти', handler: async () => {
                    await this.$store.dispatch('user/logout');
                    return this.$router.go('/').catch(() => {});
                }}
            ]
        }
    },
    computed: {
        drawer: {
            get: function(){
                return this.visible;
            },
            set: function(newValue){
                this.$emit('update:visible', newValue)
            }   
        },
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
        this.fillTaskItemWithChildrens();
    },
    methods: {
        fillTaskItemWithChildrens () {
            const taskItem = this.items.find(i => i.id === 'tasks');
            if (!taskItem) { return; }
            taskItem.children = this.$store.state.app.subjects.map(subj => ({
                    text: subj.text,
                    subGroup: true,
                    href: `/task?subject=${subj.text}`,
                    children: subj.grades.map(grade => ({
                        text: `Класс ${grade}`,
                        href: `/task?subject=${subj.text}&grade=${grade}`
                    }))
                })
            )
        }
    }
}
</script>